<template>
  <div class="main">
    <headerComponent class="pc" />
    <mobileHeaderComponent class="mobile" />

    <router-view />
    <footerComponentVue />
  </div>
</template>

<script>
import headerComponent from "./components/headerComponent.vue";
import footerComponentVue from "./components/footerComponent.vue";
import mobileHeaderComponent from "./components/mobileHeaderComponent.vue";

export default {
  name: "App",
  watch: {
    $route() {
      // scroll to top on route change smoothly
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  components: {
    headerComponent,
    mobileHeaderComponent,
    footerComponentVue,
  },
};
</script>

<style lang="scss"></style>

<style>
* {
  margin: 0;
  padding: 0;
  transition: all 0.3s ease;
  box-sizing: border-box;
  font-family: "Open Sans Regular", sans-serif;
}
:root {
  --red: #b70000;

  --grey: #eeeeee;

  --text: #231f20;

  --border: #8d8c8c;
}

.pc {
  display: block;
}

.mobile {
  display: none;
}

@media (max-width: 768px) {
  .pc {
    display: none;
  }

  .mobile {
    display: block;
  }
}
</style>
