<template>
  <div class="header">
    <div class="logo">
      <img src="../assets/logo.png" alt="logo" />
    </div>
    <div class="hamburger" @click="toggleHamburger">
      <i class="bx" :class="hamburgericon"></i>
    </div>
    <nav v-if="hamburger">
      <ul>
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/about">About Us</router-link>
        </li>
        <li>
          <router-link to="/services">Our Services</router-link>
        </li>
        <li>
          <router-link to="/products">Our Products</router-link>
        </li>
        <!-- Why Us -->
        <li>
          <router-link to="/whyus">Why Us</router-link>
        </li>
        <li>
          <router-link to="/contact">Contact Us</router-link>
        </li>
        <!-- Book An Appointment -->
        <li>
          <router-link to="/book" class="red">Book An Appointment</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "headerComponent",
  data() {
    return {
      hamburger: false,
      hamburgericon: "bx-menu",
    };
  },

  watch: {
    $route() {
      this.hamburger = false;
      this.hamburgericon = "bx-menu";
    },
  },
  methods: {
    toggleHamburger() {
      this.hamburger = !this.hamburger;
      if (this.hamburger) {
        this.hamburgericon = "bx-x";
      } else {
        this.hamburgericon = "bx-menu";
      }
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: 100;
  display: none;
}

.header .logo {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.header nav {
  transform: translate(-100%);
  display: none;
}

.header nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header nav ul li {
  list-style: none;
  margin: 0 10px;
}

.header nav ul li a {
  text-decoration: none;
  color: var(--grey);
  font-size: 1.2rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.header nav ul li a:hover {
  color: var(--text);
}

.header .hamburger {
  display: none;
}

.header .hamburger i {
  font-size: 2rem;
  color: var(--text);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 0 10px;
    display: flex;
    background: white;
  }

  .header nav::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    z-index: -1;
  }

  .header nav {
    display: block;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    height: 70vh;
    padding: 20px 0;
    z-index: 999;
    background-color: var(--grey);
    transform: translate(0);
  }

  .header nav ul {
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
  }

  .header nav ul li {
    margin: 10px 0;
    width: 80%;
    border-bottom: 1px solid var(--red);
  }

  .header nav ul li a {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    color: var(--text);
  }

  .header .hamburger {
    display: block;
  }
}
</style>
