<template>
  <div class="header">
    <div class="loading" v-if="loading">
      <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
      </div>
    </div>
    <div class="overform" v-if="form">
      <div class="form">
        <div class="input-field">
          <input type="text" v-model="name" placeholder="Name" />
        </div>
        <div class="input-field">
          <input type="text" v-model="email" placeholder="Email" />
        </div>
        <div class="input-field">
          <input type="text" v-model="phone" placeholder="Phone" />
        </div>
        <div class="input-field">
          <textarea placeholder="Message" v-model="txt"></textarea>
        </div>
        <button @click="sendmail()">Submit</button>
      </div>
      <button @click="form = false">Close</button>
    </div>
    <div class="topbar">
      <div class="info">
        <p>info@rubiifitness.com</p>
        <p>+91 9822099959</p>
      </div>
      <div class="logo">
        <img src="../assets/logo.png" alt="logo" />
      </div>
      <div class="socials">
        <p>Follow us on</p>
        <div class="icons">
          <a href="https://facebook.com/" class="fb" target="_blank">
            <i class="bx bxl-facebook"></i>
          </a>
          <a href="https://instagram.com/" class="insta" target="_blank">
            <i class="bx bxl-instagram"></i>
          </a>
          <!-- linkedin -->
          <!-- <a href="https://linkedin.com/" target="_blank">
            <i class="bx bxl-linkedin"></i>
          </a> -->
        </div>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/about">About Us</router-link>
        </li>
        <li>
          <router-link to="/services">Our Services</router-link>
        </li>
        <li>
          <router-link to="/products">Our Products</router-link>
        </li>
        <!-- Why Us -->
        <li>
          <router-link to="/whyus">Why Us</router-link>
        </li>
        <li>
          <router-link to="/contact">Contact Us</router-link>
        </li>
        <!-- Book An Appointment -->
        <li>
          <router-link to="" class="red" @click="form = true"
            >Book An Appointment</router-link
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "headerComponent",
  data() {
    return {
      form: false,
      name: "",
      email: "",
      phone: "",
      txt: "",
      loading: false,
    };
  },
  methods: {
    async sendmail() {
      if (this.name == "") {
        alert("Please enter name");
        return;
      }
      if (this.email == "") {
        alert("Please enter email");
        return;
      }
      if (this.phone == "") {
        alert("Please enter phone");
        return;
      }
      if (this.txt == "") {
        alert("Please enter message");
        return;
      }
      //       <?php
      // $to = $_GET['konala'];
      // $subject = $_GET['short'];
      // $txt = $_GET['kay'];
      // $headers = "From: ".$_GET['kutun'] . "\r\n" .
      // "CC: ceo@grapedawn.tech";

      // echo $to;
      // echo $subject;
      // echo $txt;
      // echo $headers;

      // try {
      //     mail($to,$subject,$txt,$headers);
      //     echo "success";
      // } catch (Exception $e) {
      //     echo "fail";
      // }

      // ?>

      // await this.$http
      //   .GET(
      //     "https://www.thirstyfishh.com/mailrbla.php?konala=contact@grapedawn.tech&short="
      //       .concat(this.name)
      //       .concat("&kay=")
      //       .concat(this.phone)
      //       .concat(" ")
      //       .concat(this.txt)
      //       .concat("&kutun=")
      //       .concat(this.email)
      //   )
      //   .then((response) => {
      //     console.log(response);
      //     if (response.body == "success") {
      //       alert("Message sent successfully");
      //       this.name = "";
      //       this.email = "";
      //       this.phone = "";
      //       this.txt = "";
      //     } else {
      //       alert("Something went wrong");
      //     }
      //   });

      // await fetch(
      //   "https://www.thirstyfishh.com/mailrbla.php?konala=info@rubiifitness.com&short="
      //     .concat(this.name)
      //     .concat("&kay=")
      //     .concat(this.phone)
      //     .concat(" ")
      //     .concat(this.txt)
      //     .concat("&kutun=")
      //     .concat(this.email)
      // ).then((response) => {
      //   console.log(response);
      //   if (response.body) {
      //     alert("Message sent successfully");
      //     this.name = "";
      //     this.email = "";
      //     this.phone = "";
      //     this.txt = "";
      //   } else {
      //     alert("Something went wrong");
      //   }
      // });

      this.loading = true;
      this.form = false;

      const emailData = {
        to: [
          "info@rubiifitness.com",
          "harshal30@yahoo.com",
          "toro.rahul@gmail.com",
          "ceo@grapedawn.tech",
        ],
        from: this.email,
        subject: "new message from ".concat(this.name),
        body: this.txt.concat(" ").concat(this.phone),
      };

      // fetch("http://localhost:3000/send-email", {
      fetch("http://smjadu.tfstrategies.com/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Email request failed");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Email sent successfully", data);
          this.loading = false;
          alert("Message sent successfully");
          this.name = "";
          this.email = "";
          this.phone = "";
          this.txt = "";
          // Handle success, e.g., show a success message
        })
        .catch((error) => {
          console.error("Error sending email", error);
          this.loading = false;
          // Handle errors, e.g., show an error message
        });
    },
  },

  mounted() {
    // hide topbar on scroll

    let topbar = document.querySelector(".topbar");

    // let previousscrollpos = 0;

    window.addEventListener("scroll", () => {
      console.log(window.scrollY);
      if (window.scrollY > 0) {
        topbar.style.display = "none";
      } else {
        topbar.style.display = "flex";
      }

      // //   show nav on scrolling up
      // let currentscrollpos = window.scrollY;
      // if (previousscrollpos > currentscrollpos) {
      //   topbar.style.display = "flex";
      // }
      // previousscrollpos = currentscrollpos;
    });
  },
};
</script>

<style>
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
  position: fixed;
  background-color: rgba(240, 248, 255, 0.3);
  backdrop-filter: blur(5px);
}

.loading .loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  perspective: 800px;
}

.loading .loader .inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.loading .loader .inner.one {
  left: 0%;
  top: 0%;
  animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid var(--red);
}

.loading .loader .inner.two {
  right: 0%;
  top: 0%;
  animation: rotate-two 1s linear infinite;
  border-right: 3px solid var(--red);
}

.loading .loader .inner.three {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 1s linear infinite;
  border-top: 3px solid var(--red);
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
</style>

<style scoped>
.overform {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.overform .form {
  width: 50%;
  height: 50%;
  background: white;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.overform .form .input-field {
  width: 100%;
  height: 3rem;
  margin-bottom: 1rem;
}

.overform .form .input-field input,
.overform .form .input-field textarea {
  width: 100%;
  height: 100%;
  border: 1px solid var(--border);
  outline: none;
  padding: 0 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: var(--text);
}

.overform .form .input-field textarea {
  resize: none;
}

.overform .form button {
  width: 50%;
  height: 3rem;
  border: none;
  outline: none;
  background: var(--red);
  color: white;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
}

.overform button {
  width: 50%;
  height: 3rem;
  border: none;
  outline: none;
  background: var(--red);
  color: white;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
}

.overform button:hover {
  background: white;
  color: var(--red);
  outline: 1px solid var(--red);
}

.overform .form button:hover {
  background: white;
}

.header {
  width: 100%;
  margin: 0;
  padding: 0 5%;
  z-index: 999;
  position: sticky;
  top: 0;
  left: 0;
  height: auto;
  background-color: white;
}

.header .topbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .topbar .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 15%;
}

.header .topbar .info p {
  font-size: 1rem;
  font-weight: 400;
  color: var(--text);
  margin-bottom: 0.5rem;
}

.header .topbar .logo {
  width: 70%;
  height: 8rem;
  padding: 1rem 0;
  border-bottom: 1px solid var(--border);
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .topbar .logo img {
  width: auto;
  height: 70%;
}

.header .topbar .socials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 15%;
}

.header .topbar .socials p {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--text);
  margin-bottom: 0.5rem;
}

.header .topbar .socials .icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 70%;
}

.header .topbar .socials .icons a {
  text-decoration: none;
  font-size: 1rem;
  color: white;
  transition: all 0.3s ease;
  background: var(--red);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .topbar .socials .icons a:hover {
  background: var(--grey);
  color: var(--text);
}

.header nav {
  width: 100%;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header nav ul {
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  height: fit-content;
}

.header nav ul li {
  font-size: 1rem;
  font-weight: 700;
  color: var(--text);
  height: fit-content;
}

.header nav ul li a {
  text-decoration: none;
  color: var(--text);
  transition: all 0.3s ease;
  padding: 0.5rem 0;
}

.header nav ul li a:hover {
  color: var(--red);
}

a.red {
  color: var(--red) !important;
}

@media screen and (max-width: 768px) {
  .header {
    display: none;
  }
}

.fb {
  background-color: #3b5998 !important;
}

.insta {
  /* background-color: #e4405f !important; */
  /* gradient */
  background: linear-gradient(
    45deg,
    #405de6 0%,
    #5851db 15%,
    #833ab4 30%,
    #c13584 44%,
    #e1306c 58%,
    #fd1d1d 72%,
    #f56040 86%,
    #f77737 100%
  ) !important;
}
</style>
