<template>
  <a
    href="https://api.whatsapp.com/send?phone=919822099959"
    target="blank"
    class="wa"
  >
    <i class="bx bxl-whatsapp"></i>
  </a>
  <footer>
    <div class="fbet">
      <div class="left">
        <h3>START A project with us TODAY!</h3>
        <p>
          Our capability and high-impact service quality have benefited many.
        </p>
      </div>
      <div class="right">
        <router-link to="/contact" class="btn">BOOK AN APPOINTMENT</router-link>
      </div>
    </div>
    <div class="fbet mobile">
      <div class="left">
        <p>Rubii Fitness andGym Equipment</p>
        <p>© 2023 All Rights Reserved</p>
      </div>
      <div class="right">
        <div class="innerf">
          <div class="left">
            <p>info@rubiifitness.com</p>
            <p>+91 9822099959</p>
          </div>
          <div class="right">
            <a href="https://facebook.com/" class="fb" target="_blank"
              ><i class="bx bxl-facebook"></i>
            </a>
            <a href="https://instagram.com/" class="insta" target="_blank"
              ><i class="bx bxl-instagram"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup></script>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
footer {
  padding: 0 5%;
  width: 100%;
  background-color: #fff;
  z-index: 999;
}

a.wa {
  position: fixed;
  text-decoration: none;
  bottom: 2rem;
  right: 2rem;
  z-index: 0;
  background-color: #25d366;
  color: white;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  transition: all 0.3s ease;
}

footer .fbet {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 3% 0;
  border-bottom: 2px solid var(--border);
  z-index: 999;
  background-color: #fff;
}

footer .fbet .left {
  width: 75%;
  background-color: #fff;
}

footer .fbet .left h3 {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--text);
  text-transform: uppercase;
  margin-bottom: 1rem;
}

footer .fbet .right {
  width: 25%;
}

footer .fbet .right a.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--red);
  text-decoration: none;
  border: 1px solid var(--border);
  width: 100%;
  height: 3rem;
  font-size: 1rem;
}

footer .fbet .right a.btn:hover {
  background-color: var(--red);
  color: white;
}

footer .fbet .right .innerf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
footer .fbet .right .innerf p,
footer .fbet .right .innerf a {
  font-size: 1rem;
  font-weight: 400;
  color: var(--text);
  margin-bottom: 0.5rem;
}

footer .fbet .right .innerf .left {
  width: 70%;
}

footer .fbet .right .innerf .right {
  width: 30%;
}

footer .fbet .right .innerf .right a {
  color: #fff;
  background-color: var(--red);
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  text-decoration: none;
  font-size: 1rem;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}

footer .fbet .right .innerf .right a i {
  color: #fff;
  font-size: 1.5rem;
}

footer .fbet .right .innerf .right a:hover {
  color: var(--red);
}

.fb {
  background-color: #3b5998 !important;
}

.insta {
  /* background-color: #e4405f !important; */
  /* gradient */
  background: linear-gradient(
    45deg,
    #405de6 0%,
    #5851db 15%,
    #833ab4 30%,
    #c13584 44%,
    #e1306c 58%,
    #fd1d1d 72%,
    #f56040 86%,
    #f77737 100%
  ) !important;
}

@media screen and (max-width: 768px) {
  footer .fbet {
    flex-direction: column;
    align-items: flex-start;
  }

  footer .fbet.mobile {
    flex-direction: column-reverse;
  }

  footer .fbet .left {
    width: 100%;
  }

  footer .fbet .right {
    width: 100%;
  }

  footer .fbet .right a.btn {
    width: 100%;
    margin: 1rem 0;
  }

  footer .fbet .right .innerf {
    flex-direction: column;
    align-items: flex-start;
  }

  footer .fbet .right .innerf .left {
    width: 100%;
  }

  footer .fbet .right .innerf .right {
    width: fit-content;
    float: left;
  }

  footer .fbet .right .innerf .right a {
  }
}
</style>
