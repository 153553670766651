<template>
  <div class="home">
    <div class="hero">
      <img class="pc" src="../assets/hero.png" alt="hero" />
      <img class="mobile" src="../assets/hero-mobile.png" alt="hero" />
    </div>
  </div>
  <div class="homecontainer">
    <div class="knowus">
      <h2>know us</h2>
      <div class="line"></div>
      <p>
        We are a well-established firm specializing in advanced consultancy
        services to plan & design for setting up gym & fitness spaces . Our
        expertise in both technical and scientific aspects allows us to deliver
        a seamless end-to-end service to our clients. With a proven track record
        of adding value to client spaces throughout the country, our team of
        dedicated and passionate professionals is always reliable. Since 2002,
        we have been operating in the industry, continuously growing and
        adapting to meet the evolving needs of our clients
      </p>
      <router-link to="/about" class="btn">learn More</router-link>
    </div>
    <div class="services">
      <h2>OUR SERVICES</h2>
      <div class="line"></div>
      <p>
        At our core, we believe in delivering seamless and straightforward
        solutions that our clients can enjoy and utilize to the fullest. Our
        primary objective is to integrate the client's vision with top-notch
        consultation, premium materials, innovative design, and efficient
        construction practices. By doing so, we ensure that the end result is
        not only successful but also highly rewarding for our clients.
      </p>
    </div>
    <div class="serviceinfo">
      <div class="left">
        <!-- 2 by 2 grid -->
        <div class="element">
          <div class="icon">
            <img src="../assets/chat.png" alt="" />
          </div>
          <div class="list">
            <h3>Gym Consultancy</h3>
            <!-- Concept Planning
Feasibility Study
Operator Research
Technical Assistance
Pre-Opening Services
Ongoing Management -->
            <p>
              <img src="../assets/diamondbullet.png" alt="" />
              Concept Planning
            </p>
            <p>
              <img src="../assets/diamondbullet.png" alt="" />Operator Research
            </p>
            <p>
              <img src="../assets/diamondbullet.png" alt="" />Operator Research
            </p>
            <p>
              <img src="../assets/diamondbullet.png" alt="" />Technical
              Assistance
            </p>
            <p>
              <img src="../assets/diamondbullet.png" alt="" />Pre-Opening
              Services
            </p>
            <p>
              <img src="../assets/diamondbullet.png" alt="" />Ongoing Management
            </p>
          </div>
        </div>
        <div class="element">
          <div class="icon">
            <img src="../assets/gym-building.png" alt="" />
          </div>
          <div class="list">
            <h3>Gym Setup For</h3>
            <!-- Clubs
Hotels & Resorts
Housing Projects
Personal Home Use
Corporate Houses
Outdoor
            -->
            <p><img src="../assets/diamondbullet.png" alt="" />Clubs</p>
            <p>
              <img src="../assets/diamondbullet.png" alt="" />Hotels & Resorts
            </p>
            <p>
              <img src="../assets/diamondbullet.png" alt="" />Housing Projects
            </p>
            <p>
              <img src="../assets/diamondbullet.png" alt="" />Personal Home Use
            </p>
            <p>
              <img src="../assets/diamondbullet.png" alt="" />Corporate Houses
            </p>
            <p><img src="../assets/diamondbullet.png" alt="" />Outdoor</p>
          </div>
        </div>
        <div class="element">
          <div class="icon">
            <img src="../assets/floor.png" />
          </div>
          <div class="list">
            <h3>Sports Flooring</h3>
            <!-- Courts (Badminton, Tennis)
Gyms
Jogging Tracks in Parks 
Synthetic Running Tracks
Children Play Areas
Artificial Turf Courts
            -->
            <p>
              <img src="../assets/diamondbullet.png" alt="" />Courts (Badminton,
              Tennis)
            </p>
            <p><img src="../assets/diamondbullet.png" alt="" />Gyms</p>
            <p>
              <img src="../assets/diamondbullet.png" alt="" />Jogging Tracks in
              Parks
            </p>
            <p>
              <img src="../assets/diamondbullet.png" alt="" />Synthetic Running
              Tracks
            </p>
            <p>
              <img src="../assets/diamondbullet.png" alt="" />Children Play
              Areas
            </p>
            <p>
              <img src="../assets/diamondbullet.png" alt="" />Artificial Turf
              Courts
            </p>
          </div>
        </div>
        <!-- Clubs
Hotels & Resorts
Housing Projects
Outdoor -->
        <div class="element">
          <div class="icon">
            <img src="../assets/slider.png" alt="" />
          </div>
          <div class="list">
            <h3>Kids Play Area</h3>
            <!-- Clubs
Hotels & Resorts
Housing Projects
Outdoor
            -->
            <p><img src="../assets/diamondbullet.png" alt="" />Clubs</p>
            <p>
              <img src="../assets/diamondbullet.png" alt="" />Hotels & Resorts
            </p>
            <p>
              <img src="../assets/diamondbullet.png" alt="" />Housing Projects
            </p>
            <p><img src="../assets/diamondbullet.png" alt="" />Outdoor</p>
          </div>
        </div>
      </div>
      <div class="right">
        <img src="../assets/ourservicehome.png" alt="" />
      </div>
    </div>
    <div class="san">
      <div class="line"></div>
      <router-link to="/services" class="btn"
        >Schedule an Appointment</router-link
      >
    </div>
    <div class="circlesec">
      <h2>RELY ON US FOR BEST OUTCOME!</h2>

      <div class="line"></div>

      <p>
        Step by step handholding for developing a lifestyle and fitness club.
      </p>
      <img src="../assets/relayonus.png" alt="our process" />
      <router-link to="/contact" class="btn">START YOUR DREAM</router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
  created() {
    document.title = "Rubii Gym | Home";
  },
};
</script>

<style scoped>
.hero {
  width: 100%;
  height: 40vw;
  object-fit: cover;
  overflow: hidden;
}

.hero img.mobile {
  display: none;
}

.hero img.pc {
  width: 99vw;
  height: 40vw;
  object-fit: cover;
}

.homecontainer {
  width: 100%;
  height: auto;
  padding: 0 5%;
}

.knowus {
  width: 100%;
  height: auto;
  padding: 5% 15%;
  text-align: center;
  background: var(--grey);
}

h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  text-transform: uppercase;
}

.line {
  width: 3px;
  height: 3rem;
  background-color: #333;
  margin: 2rem auto;
}

p {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--text);
  margin-bottom: 4rem;
  text-align: center;
}

a.btn {
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--red);
  transition: all 0.3s ease;
  border: 1px solid var(--border);
  padding: 1rem 3rem;
  text-transform: uppercase;
  margin: 0 auto;
}

.knowus a.btn:hover {
  background-color: var(--red);
  color: white;
}

.services {
  width: 100%;

  height: auto;
  padding: 5% 15%;
  text-align: center;
  padding-bottom: 0;
}

.serviceinfo {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left {
  width: 50%;
  height: auto;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.element {
  width: 45%;
  height: auto;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.element .icon {
  height: 5rem;
  width: 5rem;
}

.element .icon img {
  height: 4rem;
}

.element .list {
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: left;
}

.element .list h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text);
  margin-bottom: 1rem;
}

.element .list p {
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--text);
  margin-bottom: 0.5rem;
  text-align: left;
  height: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.element .list p img {
  height: 1rem;
  margin-right: 0.5rem;
}

.right {
  width: 50%;
  height: auto;
  text-align: center;
}

.right img {
  width: 100%;
  height: auto;
}

.san {
  width: 100%;
  height: auto;
  padding: 5% 5%;
  text-align: center;
}

.circlesec {
  width: 100%;
  height: auto;
  padding: 5% 15%;
  text-align: center;
  background: url(../assets/relayonusbg.png);
}

.circlesec img {
  width: 100%;
  height: auto;
  margin-bottom: 4rem;
}

.circlesec a.btn {
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--red);
  transition: all 0.3s ease;
  border: 1px solid var(--border);
  padding: 1rem 3rem;
  text-transform: uppercase;
  margin: 0 auto;
}

.circlesec a.btn:hover {
  background-color: var(--red);
  color: white;
}

@media screen and (max-width: 768px) {
  .hero {
    width: 100%;
    height: 100vw;
    object-fit: cover;
    overflow: hidden;
  }

  .hero img.pc {
    display: none;
  }

  .hero img.mobile {
    display: block;
    width: 100%;
    height: 100vw;
    object-fit: cover;
  }

  .homecontainer {
    width: 100%;
    height: auto;
    padding: 0 3%;
  }

  .knowus {
    width: 100%;
    height: auto;
    padding: 5% 5%;
    text-align: center;
    background: var(--grey);
  }

  h2 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    text-transform: uppercase;
  }

  .line {
    width: 2px;
    height: 2rem;
    background-color: #333;
    margin: 1rem auto;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: var(--text);
    margin-bottom: 2rem;
    text-align: center;
  }

  a.btn {
    display: block;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--red);
    transition: all 0.3s ease;
    border: 1px solid var(--border);
    padding: 0.5rem 2rem;
    text-transform: uppercase;
    margin: 0 auto;
  }

  .services {
    width: 100%;

    height: auto;
    padding: 5% 5%;
    text-align: center;
    padding-bottom: 0;
  }

  .serviceinfo {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  .right {
    width: 100%;
    height: auto;
    text-align: center;
  }

  .right img {
    width: 100%;
    height: auto;
  }

  .left {
    width: 100%;
    height: auto;
    text-align: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .element {
    width: 50%;
    height: auto;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .element .icon {
    height: 3rem;
    width: 2rem;
  }

  .element .icon img {
    height: 2rem;
  }

  .element .list {
    width: 100%;
    height: auto;
    margin: 0 auto;
    text-align: left;
  }

  .element .list h3 {
    font-size: 1rem;
    font-weight: 600;
    color: var(--text);
    margin-bottom: 1rem;
  }

  .element .list p {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--text);
    margin-bottom: 0.5rem;
    text-align: left;
    height: 1.5rem;
    float: right;
  }

  .element .list p img {
    height: 1rem;
    margin-right: 0.5rem;
  }

  .san {
    width: 100%;
    height: auto;
    padding: 5% 5%;
    text-align: center;
  }

  .circlesec {
    width: 100%;
    height: auto;
    padding: 5% 5%;
    text-align: center;
    background: url(../assets/relayonusbg-mobile.png);
  }

  .circlesec img {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
  }

  .circlesec a.btn {
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    color: var(--red);
    transition: all 0.3s ease;
    border: 1px solid var(--border);
    padding: 1rem 3rem;
    text-transform: uppercase;
    margin: 0 auto;
  }
}
</style>
